<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Item')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>

            <!-- Field: SKU -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="sku"
                rules="required"
              >
                <b-form-group
                  :label="$t('SKU')"
                  label-for="sku"
                >
                  <b-form-input
                    id="sku"
                    v-model="itemData.sku"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Item Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="itemData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Category -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Item Category -->
              <validation-provider
                #default="validationContext"
                name="category"
                rules="required"
              >
                <b-form-group
                  :label="$t('Category')"
                  label-for="category"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="itemData.category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categoryOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="category"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Sale Price -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="sale-price"
                rules="required"
              >
                <b-form-group
                  :label="$t('Sale Price')"
                  label-for="sale-price"
                >
                  <b-form-input
                    id="sale-price"
                    v-model="itemData.sale_price"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Purchase Sale Price -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="purchase-price"
                rules="required"
              >
                <b-form-group
                  :label="$t('Purchase Price')"
                  label-for="purchase-price"
                >
                  <b-form-input
                    id="purchase-price"
                    v-model="itemData.purchase_price"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Unit -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Item Unit -->
              <validation-provider
                #default="validationContext"
                name="unit"
                rules="required"
              >
                <b-form-group
                  :label="$t('Unit')"
                  label-for="unit"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="itemData.unit_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="unitOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="unit"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Quantity -->
            <b-col
              md="4"
            >
              <b-form-group
                :label="$t('Quantity')"
                label-for="quantity"
              >
                <b-form-input
                  id="quantity"
                  v-model="itemData.quantity"
                  trim
                  placeholder=""
                />

              </b-form-group>
            </b-col>

            <!-- Field: Branch -->
            <b-col
              v-if="$can('manage', 'all')"
              cols="12"
              md="4"
            >
              <!-- Item Branch -->
              <b-form-group
                :label="$t('Branch')"
                label-for="branch_id"
              >
                <v-select
                  v-model="itemData.branch_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="branchOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="branch_id"
                />
              </b-form-group>

            </b-col>

          </b-row>

          <hr>

          <h4 class="mb-1">
            {{ $t('Multiple') + ' ' + $t('Pricing') }}
          </h4>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="addMPricingItem"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />{{ $t('Add') }}
          </b-button>

          <b-form
            ref="prform"
            class="repeater-form mt-1"
          >

            <!-- Row Loop -->
            <b-row
              v-for="(pr, index) in itemData.pricing"
              :id="pr.id"
              :key="pr.id"
              ref="row"
            >

              <!-- Name -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="pr.id + '-name'"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Name')"
                    :label-for="pr.id + '-name'"
                  >
                    <b-form-input
                      :id="pr.id + '-name'"
                      v-model="pr.name"
                      type="text"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Price -->
              <b-col md="2">
                <validation-provider
                  #default="validationContext"
                  :name="pr.id + '-price'"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Price')"
                    :label-for="pr.id + '-price'"
                  >
                    <b-form-input
                      :id="pr.id + '-price'"
                      v-model="pr.price"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Minimum Quantity -->
              <b-col md="2">
                <validation-provider
                  #default="validationContext"
                  :name="pr.id + '-minimum-quantity'"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Minimum') + ' ' + $t('Quantity')"
                    :label-for="pr.id + '-minimum-quantity'"
                  >
                    <b-form-input
                      :id="pr.id + '-minimum-quantity'"
                      v-model="pr.minimum_quantity"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Maximum Quantity -->
              <b-col md="2">
                <validation-provider
                  #default="validationContext"
                  :name="pr.id + '-maximum-quantity'"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Maximum') + ' ' + $t('Quantity')"
                    :label-for="pr.id + '-maximum-quantity'"
                  >
                    <b-form-input
                      :id="pr.id + '-maximum-quantity'"
                      v-model="pr.maximum_quantity"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider></b-col>

              <!-- Remove Button -->
              <b-col
                lg="2"
                md="2"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="flat-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>{{ $t('Delete') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </b-form>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-1"
              pill
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="gradient-secondary"
              pill
              @click="cancel"
            >

              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import itemStoreModule from '../itemStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      categoryOptions: [],
      unitOptions: [],
      branchOptions: [],
      nextTodoId: 1,
    }
  },
  mounted() {
    this.loadCategories()
    this.loadBranches()
    this.loadUnits()
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    loadCategories() {
      store
        .dispatch('common/fetchCategories')
        .then(response => {
          const { categories } = response.data.data
          for (let i = 0; i < categories.length; i += 1) {
            this.categoryOptions.push({ label: categories[i].name, value: categories[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadUnits() {
      store
        .dispatch('common/fetchUnits')
        .then(response => {
          const { units } = response.data.data
          for (let i = 0; i < units.length; i += 1) {
            this.unitOptions.push({ label: units[i].name, value: units[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancel() {
      this.$router.push({ name: 'item-list' })
    },
    addMPricingItem() {
      this.itemData.pricing.push({
        id: this.nextTodoId += 1,
        name: '',
        price: null,
        minimum_quantity: null,
        maximum_quantity: 0, // 0 means unlimit the maximum price
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.itemData.pricing.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.prform.scrollHeight)
      })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'item'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankItemData = {
      sku: '',
      name: '',
      category_id: null,
      sale_price: null,
      purchase_price: null,
      unit_id: null,
      pricing: [],
      quantity: 0,
      branch_id: null,
    }
    const router = context.root.$router
    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const itemId = router.currentRoute.params.id
    store.dispatch('item/fetchOne', { id: itemId })
      .then(response => {
        const responseData = {
          id: response.data.data.id,
          sku: response.data.data.sku,
          name: response.data.data.name,
          category_id: parseInt(response.data.data.category_id, 10),
          sale_price: response.data.data.sale_price,
          wholesale_price: response.data.data.wholesale_price,
          purchase_price: response.data.data.purchase_price,
          unit_id: parseInt(response.data.data.unit_id, 10),
          pricing: response.data.data.pricing,
          quantity: response.data.data.inventory !== null ? response.data.data.inventory.closing_quantity : 0,
          branch_id: parseInt(response.data.data.branch_id, 10),
        }
        itemData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          itemData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(itemData.value))
      store.dispatch('item/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'item-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
